import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { prettifySeconds, trim } from "src/helpers";
import { info } from 'src/slices/MessagesSlice';
import { ethers } from "ethers";
import {
	Paper,
	Typography,
	Zoom,
	Button,
	OutlinedInput,
	InputAdornment,
	FormControl,
	InputLabel
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import "./ido.scss";
import useIdo from "src/hooks/useIdo";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";
import ethIcon from "src/assets/tokens/ethereum.png"
import GNS from "src/assets/tokens/GNS.svg"
import BigNumber from 'bignumber.js';
const parseRemainTime = (remainTime) => {
	let time = remainTime

	const days = Math.floor(time / (1 * 60 * 60 * 24))
	time = time % (1 * 60 * 60 * 24)

	const hours = Math.floor(time / (1 * 60 * 60))
	time = time % (1 * 60 * 60)

	const minutes = Math.floor(time / (1 * 60))
	time = time % (1 * 60)

	const seconds = Math.floor(time)
	time = time % 1

	return {
		days,
		hours,
		minutes,
		seconds
	}
}

function Ido() {
	const dispatch = useDispatch()
	const { goal, raised, isWhited, ethBalance, handlerBuy, price, roundTime, wBougth, pBougth } = useIdo()

	const pendingTransactions = useSelector(state => {
		return state.pendingTransactions;
	});

	const Round = [
		{
			bout: 0,// 未开始
			maxBuy: 0,// 最大购买数量
			endTime: roundTime[0] || 0, // og开始
		},
		{
			bout: 1,// 第一轮 og
			maxBuy: 20000000000,// 最大购买数量 2 ETH
			endTime: roundTime[1] || 0, // og结束,wl开始
		},
		{
			bout: 2,// 第二轮 wl
			maxBuy: 20000000000,// 最大购买数量 2 ETH
			endTime: roundTime[2] || 0,// wl结束,公开售卖开始
		},
		{
			bout: 3, // 第三轮 公开
			maxBuy: 20000000000,// 最大购买数量 2 ETH
			endTime: roundTime[3] || 0,// 公开售卖结束
		}
	]

	const [estimateValue, setEstimate] = useState('0');// 能 兑换 多少
	const [time, setTime] = useState({ days: '-', hours: '-', minutes: '-', seconds: '-' })
	const [active, setActive] = useState(0);// 0 未开始 123...  -1 已结束
	const [quantity, setQuantity] = useState("");// 用户输入ETH数量

	// 根据当前时间，计算第几轮和剩余时间
	const calc = () => {
		const nowTime = new Date().getTime() / 1000;
		const roundFilter = Round.filter(e => e.endTime > nowTime);
		let endTime = 0;
		// 计算当前时间位于第几轮
		if (roundFilter && roundFilter.length > 0) {
			endTime = roundFilter[0].endTime;
			setActive(roundFilter[0].bout)
		}
		// 已结束
		else if (roundFilter && roundFilter.length === 0) {
			setActive(-1)
		}

		const leftTime = endTime > nowTime ? endTime - nowTime : 0;

		setTime(parseRemainTime(leftTime))
	}
	// 进入页面开启倒计时
	useEffect(() => {
		const intervalId = setInterval(() => {
			calc()
		}, 1000);

		return () => clearInterval(intervalId);
	}, [time]);

	useEffect(() => {
		setEstimate(quantity * 1500 / price)
	}, [price, quantity])


	// 输入最大
	const setMax = () => {
		// if (active <= 0) {
		// 	dispatch(info("end"))
		// 	return;
		// }
		// const roundFilter = Round.filter(e => e.bout === active);
		let maxBuy = new BigNumber(2);
		let input = new BigNumber(0);
		// 第一轮最大购买是 2 - 一轮已经购买
		if (wBougth && (active === 1 || active === 2)) {
			input = new BigNumber(maxBuy.minus(wBougth));
		}
		// 第二轮最大购买是 2 - 二轮已经购买
		if (pBougth && active === 3) {
			input = new BigNumber(maxBuy.minus(pBougth));
		}

		if (input.comparedTo(new BigNumber(ethBalance)) < 0) {
			setQuantity(input)
		} else {
			setQuantity(ethBalance)
		}

	}

	const confim = () => {
		// if (active === 0) {
		// 	dispatch(info("ido not started."))
		// 	return;
		// }
		// if (active === -1) {
		// 	dispatch(info("Ido is closed."))
		// 	return;
		// }
		handlerBuy(quantity)
	}

	return (
		<div id="ido-info-view">
			<Zoom in={true}>
				<Paper className="ido-time">
					<div>
						Remaining Time
					</div>
					{
						roundTime && roundTime.length === 0 ?
							<Skeleton width="80px" height="30px" style={{ background: `rgba(54, 56, 64, 0.4)` }} />
							:
							<div>
								{time?.days}:{time?.hours}:{time?.minutes}:{time?.seconds}
							</div>
					}

				</Paper>
			</Zoom>
			<Zoom in={true}>
				<Paper className="ido-total">
					<div className="ido-total-item">
						<div>Fundraise Goal</div>
						<div>{new Intl.NumberFormat("en-US").format(trim(goal, 4))} ETH</div>
					</div>
					<div className="ido-total-item">
						<div>Total Raised</div>
						<div>{new Intl.NumberFormat("en-US").format(trim(raised, 4))} ETH</div>
					</div>
					<div className="ido-total-item">
						<div>#KILLER Price</div>
						<div>{trim(price, 6)} USDC</div>
					</div>
					<div className="ido-total-item">
						<div>Whitelist</div>
						<div>{isWhited ? 'YES' : 'NO'}</div>
					</div>
				</Paper>
			</Zoom>

			<Zoom in={true}>
				<Paper className="ido-card">
					<div className="ido-card-buy">- BUY -</div>
					<FormControl className="gns-input" variant="outlined" color="primary" fullWidth>
						<InputLabel htmlFor="outlined-adornment-amount" style={{
							height: `60px`, lineHeight: `30px`,
							fontSize: `18px`
						}}>Spend</InputLabel>
						<OutlinedInput
							className="ido-card-amount"
							type="number"
							value={quantity}
							onChange={e => setQuantity(e.target.value)}
							labelWidth={55}
							endAdornment={
								<InputAdornment position="end">
									<Button variant="text" onClick={setMax}>
										Max
									</Button>
									<div className="eth">
										<img className="ethIcon" src={ethIcon} alt="" />
									</div>
									<div className="symbol">
										ETH
									</div>
								</InputAdornment>
							}
						/>
						<div className="balance">Balance: {new Intl.NumberFormat("en-US").format(trim(ethBalance, 2))} ETH</div>
						<div className="ido-card-receive">
							<div className="receive">
								<span className="name">Receive</span>
								<span className="token-amount">{new Intl.NumberFormat("en-US").format(trim(estimateValue || 0, 2))}</span>
								<img className="token-icon" src={GNS} alt="" />
								<span className="token-symbol">KILLER</span>
							</div>
							<Button
								disabled={isPendingTxn(pendingTransactions, "buy_killer")}
								variant="outlined"
								color="primary"
								className="confirm"
								onClick={() => { confim() }}>
								<Typography variant="h6">{txnButtonText(pendingTransactions, "buy_killer", "CONFIRM")}</Typography>
							</Button>
						</div>
						{
							(wBougth != 0 || pBougth != 0) &&
							<div className="ido-card-bougth">
								{
									wBougth != 0 &&
									(<div className="ido-card-role">
										<div>
											Pre-sale confirmed
										</div>
										<div>
											{wBougth} <span>ETH</span>
										</div>
									</div>)
								}
								{
									pBougth != 0 && (<div className="ido-card-role">
										<div>
											Public Sale confirmed
										</div>
										<div>
											{pBougth} <span>ETH</span>
										</div>
									</div>)
								}

							</div>

						}

					</FormControl>
				</Paper>
			</Zoom>
		</div>
	)
}
export default Ido