import { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Typography, SvgIcon, Backdrop, Box, Fade, Grid, Paper, FormControl, InputAdornment, InputLabel, OutlinedInput, IconButton, } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { ReactComponent as XIcon } from "../../assets/icons/x.svg";
import { ReactComponent as ArrowLight } from "../../assets/icons/arrows1.svg";
import { ReactComponent as ArrowDark } from "../../assets/icons/arrows.svg";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";
import { trim } from "src/helpers";
import { ethers } from "ethers";
import useWrap from "src/hooks/useWrap";
export default function OpenWrap({ theme }) {

    const [showFade, setShowFade] = useState(false)
    const [isWrap, setIsWrap] = useState(true)
    const [inputValue, setInputValue] = useState('')
    const [outValue, setOutValue] = useState('')
    const { approved, CalculateWrap, DoSwap, DoApprove } = useWrap()

    const setMax = () => {
        isWrap ? setInputValue(sohmBalance) : setInputValue(wsohmBalance)
    }

    useEffect(() => {
        if (!inputValue || inputValue == '0') {
            setOutValue('')
        } else {
            CalculateWrap(inputValue, isWrap).then(res => setOutValue(res)).catch()
        }
    }, [inputValue])
    const handleWrap = async () => {
        await DoSwap(inputValue, isWrap)
    }
    const isAppLoading = useSelector(state => state.app.loading)

    const pendingTransactions = useSelector(state => {
        return state.pendingTransactions;
    })

    const sohmBalance = useSelector(state => {
        return state.account.balances && state.account.balances.sohm;
    })

    const wsohmBalance = useSelector(state => {
        return state.account.balances && state.account.balances.wsohmBalance;
    })

    const wtosBalance = useSelector(state => {
        return state.app.wtosBalance;
    })

    const stowBalance = useSelector(state => {
        return state.app.stowBalance;
    })
    return (
        <div className="wallet-menu" id="wallet-menu">
            <Button variant="contained" color="secondary" size="large" onClick={() => { setShowFade(true) }}  >   Wrap </Button>
            <Fade in={showFade} mountOnEnter unmountOnExit>
                <Grid container id="bond-view">
                    <Backdrop open={true}>
                        <Fade in={true}>
                            <Paper className="gns-card card-border" style={{ maxWidth: "600px" }}>

                                <Box display="flex" justifyContent="space-between" alignItems="center" >
                                    <IconButton onClick={() => { setShowFade(), setInputValue(''), setOutValue('') }} >
                                        <SvgIcon color="primary" component={XIcon} />
                                    </IconButton>
                                    <Typography variant="h5">
                                        {isWrap && <>{stowBalance ? `1 sKILLER = ${new Intl.NumberFormat("en-US").format(trim(ethers.utils.formatUnits(stowBalance, 9), 4))} wKILLER` : <Skeleton width="180px" />} </>}
                                        {!isWrap && <>{wtosBalance ? `1 wKILLER = ${new Intl.NumberFormat("en-US").format(trim(ethers.utils.formatUnits(wtosBalance, 9), 4))} sKILLER` : <Skeleton width="180px" />} </>}
                                    </Typography>
                                </Box>

                                <Box display="flex" justifyContent="center" flexDirection="column" style={{ margin: "0 30px" }}>
                                    <Box className="mt-12" display="flex" justifyContent="space-between" >
                                        <Typography variant="h6" color="textSecondary">
                                            Wrap
                                        </Typography>
                                        <Typography variant="h6" color="textSecondary">
                                            {isWrap && <>  {isAppLoading ? <Skeleton width="80px" /> : <> Balance:  {new Intl.NumberFormat("en-US").format(trim(sohmBalance, 4))} sKILLER </>}</>}
                                            {!isWrap && <>  {isAppLoading ? <Skeleton width="80px" /> : <> Balance:  {new Intl.NumberFormat("en-US").format(trim(wsohmBalance, 4))} wKILLER </>}</>}
                                        </Typography>
                                    </Box>
                                    <Box className="mt-12" display="flex" alignItems="center">
                                        <FormControl className="ssgns-input" variant="outlined" color="primary" fullWidth>
                                            <InputLabel htmlFor="outlined-adornment-amount"> {isWrap ? 'sKILLER' : 'wKILLER'}</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                id="outlined-adornment-amount"
                                                type="number"
                                                placeholder="Enter an amount"
                                                className="stake-input"
                                                value={inputValue}
                                                onChange={e => setInputValue(e.target.value)}
                                                labelWidth={55}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <Button variant="text"
                                                            onClick={setMax}
                                                            color="inherit">
                                                            Max
                                                        </Button>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    </Box>
                                    <Box className="" display="flex" alignItems="center" justifyContent="center" >
                                        <IconButton onClick={() => { setInputValue(''), setIsWrap(!isWrap), setOutValue('') }} >
                                            <SvgIcon viewBox="0 0 25 25" color="primary" component={theme === "dark" ? ArrowDark : ArrowLight} />
                                        </IconButton>
                                    </Box>
                                    <Box className="" display="flex" alignItems="center" >
                                        <FormControl className="ssgns-input" variant="outlined" color="primary" fullWidth>
                                            <InputLabel htmlFor="outlined-adornment-amount">
                                                {!isWrap ? 'sKILLER' : 'wKILLER'}
                                            </InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                id="outlined-adornment-amount"
                                                type="number"
                                                placeholder="Enter an amount"
                                                className="stake-input"
                                                labelWidth={55}
                                                disabled
                                                value={trim(outValue, 4)}
                                            />
                                        </FormControl>
                                    </Box>
                                    <Box className="mt-12" display="flex" alignItems="center" >
                                        {approved ?
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                disabled={isPendingTxn(pendingTransactions, "DoWrap")}
                                                onClick={handleWrap}
                                            >
                                                {txnButtonText(pendingTransactions, "DoWrap", isWrap ? "Wrap" : "UnWrap")}
                                            </Button>
                                            :
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                disabled={isPendingTxn(pendingTransactions, "Approve_sKILLER")}
                                                onClick={DoApprove}
                                            >
                                                {txnButtonText(pendingTransactions, "Approve_sKILLER", "Approve")}
                                            </Button>

                                        }
                                    </Box>
                                </Box>
                            </Paper>
                        </Fade>
                    </Backdrop>
                </Grid>
            </Fade>
        </div>
    )
}
