
import { ethers } from 'ethers';
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { addresses } from 'src/constants';
import { loadAccountDetails } from 'src/slices/AccountSlice';
import { IJsonRPCError } from 'src/slices/interfaces';
import { error } from 'src/slices/MessagesSlice';
import { clearPendingTxn, fetchPendingTxns } from 'src/slices/PendingTxnsSlice';
import { useWeb3Context } from '.'
import { abi as ierc20Abi } from "../abi/IERC20.json";
import { abi as wsOHM } from "../abi/wsOHM.json";
export default function useWrap() {
    const dispatch = useDispatch()
    const { chainID, address, provider } = useWeb3Context()
    const [approved, setApproved] = useState(false)

    useEffect(() => {
        initData().then()
    }, [address, provider, chainID])


    const initData = useCallback(
        async () => {
            if (provider && chainID && address) {
                try {
                    const tokenContract = new ethers.Contract(addresses[chainID].SOHM_ADDRESS as string, ierc20Abi, provider)
                    const allowance = await tokenContract.allowance(address, addresses[chainID].WSOHM_ADDRESS)
                    setApproved(allowance > 0)
                } catch (error) {
                    // console.log(error)
                }
            }
        },
        [address, provider, chainID])


    const CalculateWrap = useCallback(
        async (value: string, isWrap: boolean) => {
            if (value == '0') {
                return '0'
            }
            if (chainID && provider) {
                const wsohmContract = new ethers.Contract(addresses[chainID].WSOHM_ADDRESS as string, wsOHM, provider);
                try {
                    return (!isWrap ? ethers.utils.formatUnits(await wsohmContract.wKILLERTosKILLER(ethers.utils.parseUnits(value, 9)), 9) : ethers.utils.formatUnits(await wsohmContract.sKILLERTowKILLER(ethers.utils.parseUnits(value, 9)), 9))
                } catch (e) {
                }
            }
        },
        [chainID, provider],
    )

    const DoApprove = useCallback(
        async () => {
            if (address && provider && chainID) {
                const signer = provider.getSigner()
                const tokenContract = new ethers.Contract(addresses[chainID].SOHM_ADDRESS as string, ierc20Abi, signer)
                let tx;
                try {
                    tx = await tokenContract.approve(
                        addresses[chainID].WSOHM_ADDRESS,
                        ethers.utils.parseUnits("1000000000", "ether").toString()
                    )
                    dispatch(
                        fetchPendingTxns({ txnHash: tx.hash, text: "Approve", type: "Approve_sKILLER" })
                    );
                    await tx.wait()
                    return tx
                } catch (e: unknown) {
                    const rpcError = e as IJsonRPCError;
                    rpcError.data ? dispatch(error(rpcError.data.message)) : dispatch(error(rpcError.message))
                } finally {
                    if (tx) {
                        initData().then()
                        dispatch(clearPendingTxn(tx.hash));
                    }
                }
            }
        },
        [chainID, address, provider],
    )

    const DoSwap = useCallback(
        async (value: string, isWrap: boolean) => {
            if (address && provider && chainID && value) {
                const signer = provider.getSigner()
                const wsohmContract = new ethers.Contract(addresses[chainID].WSOHM_ADDRESS as string, wsOHM, signer);
                let tx;
                try {
                    tx = isWrap ? await wsohmContract.wrap(ethers.utils.parseUnits(value, 9).toString()) : await wsohmContract.unwrap(ethers.utils.parseUnits(value, 9).toString())
                    dispatch(
                        fetchPendingTxns({ txnHash: tx.hash, text: "DoWrap", type: "DoWrap" })
                    );
                    await tx.wait()
                    return tx
                } catch (e: unknown) {
                    const rpcError = e as IJsonRPCError;
                    rpcError.data ? dispatch(error(rpcError.data.message)) : dispatch(error(rpcError.message))
                } finally {
                    if (tx) {
                        initData().then()
                        dispatch(clearPendingTxn(tx.hash))
                        dispatch(loadAccountDetails({ address, networkID: chainID, provider }));
                    }
                }
            }
        }, [chainID, address, provider])

    return { approved, CalculateWrap, DoSwap, DoApprove }

}
