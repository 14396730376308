// NOTE could get this from an outside source since it changes slightly over time
export const BLOCK_RATE_SECONDS = 0.9; //FTM
//export const BLOCK_RATE_SECONDS = 3; //TODO:BSC
export const TOKEN_DECIMALS = 9;


interface IAddresses {
  [key: number]: { [key: string]: string };
}

export const addresses: IAddresses = {
  250: {
    OHM_ADDRESS: "0x5A272C478609F448C566c7155577f6432fdd8B79", //
    STAKING_ADDRESS: "0x319995E79c662479d42C054fdAB0415A6404190D",
    STAKING_HELPER_ADDRESS: "0xEbB86efd194eBAAA4E43C226488cE4E48F5B6A46",
    SOHM_ADDRESS: "0xc59a271F7625f2195C1C38f8720DA00A52A72B10",
    DISTRIBUTOR_ADDRESS: "0x9810fDAaE5CC5adF687799B3C8eE607E163437ef",
    BONDINGCALC_ADDRESS: "0x26d7c55ed6aF27ea23C64aB363eCEe4ffd4b0A93",
    TREASURY_ADDRESS: "0x00D7C1aB51A18203EF7458A2991a2e813f06c4d2",
    USDC_ADDRESS: "0x04068da6c83afcfa0e13ba15a6696662335d5b75", //
    ASOHM_ADDRESS: '0x4c0343c5605bd8039AD242C0d8B7211186Ec3253', //
    ASOHM_MIGRATION_ADDRESS: '0x1E1e8EfdB3e8A84CD726bADdeB090b2874c85907', //
    DAO_ADDRESS: '0xbA28F45B291C48bFa30F0270A5B02e23643556AD',
    LP_ADDRESS: '0x937813380c7b98a66Afa5992bf2231E3E5913eF4',
    REDEEM_HELPER_ADDRESS: "0x9EEb0524692150Fc5DBe76169aBb936638E00138",
    WSOHM_ADDRESS: "0x7A32Ae9F4E1139b7AE96A0B971332E2B9af72b56",
    vy: "0x8761C198D28963Bd539911c17B50BBEB15F8BB95",
    stake: "0xE877913f897eC93084F148A729181720a0320F2A",
    stakehelper: "0x3916ee21d2Db7e8b550c966Be3EeA6ec9E5EAECc",
  },
  97: {
    OHM_ADDRESS: "0xea65d357f005Dd52f7102C675974D948f509A99F", //
    STAKING_ADDRESS: "0xBCbC558d4aB44F31F7F002c8fC1507239C9F9791",
    STAKING_HELPER_ADDRESS: "0x245d6edFB96046692FD826bB82cee2AdfFaBAA5C",
    SOHM_ADDRESS: "0x781951f206c404A55001D9801Bf16Bd560a376B1",
    DISTRIBUTOR_ADDRESS: "0xAc233Cd55CEdc044887564b5B7de81d2Ad9E20fA",
    BONDINGCALC_ADDRESS: "0x5B387EB14ee7520269D60F522B734b8a0c1669D0",
    TREASURY_ADDRESS: "0x5758Cd0F227C8B73817c37dA88013E03556E4ef2",
    USDC_ADDRESS: "0x8e088E79d554a75d511b390E021839f7877Ce0Aa", //
    DAO_ADDRESS: '0xE6ce6781a8b1367A77D1494a6E57Ee8A0Ce64979',
    LP_ADDRESS: '0xd67dDd14fDeFB8d1F110Ea937CcE7E6271BD3Cd0',
    REDEEM_HELPER_ADDRESS: "0xC5846f3C9747fD6B3f8F1507e26De63D3C0eAE91",
    WSOHM_ADDRESS: "0x1fF23Eb6F7b7183045DE8d750f017009fE239EC2",
    ASOHM_ADDRESS: '0x4c0343c5605bd8039AD242C0d8B7211186Ec3253', //
    ASOHM_MIGRATION_ADDRESS: '0x1E1e8EfdB3e8A84CD726bADdeB090b2874c85907', //
    /*
    alphaKiller 0x85b608a74F0e442b7876D8163e7f5a18F04CA1F3
killerPreSale 0xA8970a2F1D9AF766c1437b589B3bAd4180825932
    */
    AlphaKiller: "0x85b608a74F0e442b7876D8163e7f5a18F04CA1F3",
    KillerPreSale: "0xA8970a2F1D9AF766c1437b589B3bAd4180825932",
    vy: "0x247D6a00774002016f8a965858316E6E35a0Ea21",
    stake: "0x931f134791aAFfc7f4370270773CaA60EC21B198",
    stakehelper: "0x2faAe1A7792fe28ab24FE1721b4DA65fCf19d47D",
  }
};



